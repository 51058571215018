<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- {{equipment_code_gen}}
    {{registration_number}} -->
    <div class="row justify-content-center justify-content-around">
      <!-- <div class="demo-inline-spacing">
        <b-button variant="outline-primary" @click="showAlert">
          Basic
        </b-button>
      </div> -->
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">รับเข้าทรัพย์สิน</p>
            <hr />
          </div>
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-6 row">
              <div class="col-12 col-md-2 mt-1">
                <b-button class="btn" variant="success" v-b-modal.modal-1 @click="carray"> เพิ่มข้อมูล </b-button>
              </div>
              <div class="col-12 col-md-4 mt-1">
                <b-button class="btn" variant="warning" v-b-modal.modal-1 @click="carray_register">
                  เพิ่มข้อมูลกลุ่มทะเบียนทรัพย์สิน
                </b-button>
              </div>
            </div>

            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <span><feather-icon icon="FileIcon" class="mr-50" /> Excel</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'ดูรายการ'">
                  <span>
                    <b-button variant="outline-primary" class="btn-icon mr-1" @click="dateField(props)">
                      <feather-icon icon="EyeIcon" size="16" class="mx-1" />
                    </b-button>
                  </span>
                </span>
                <span v-else-if="props.column.field === 'delete'">
                  <span>
                    <b-button variant="outline-primary" class="btn-icon mr-1" @click="del_data(props.row)">
                      <feather-icon icon="Trash2Icon" size="16" class="mx-1" />
                    </b-button>
                  </span>
                </span>
                <span v-else-if="props.column.field === 'edit'">
                  <span>
                    <b-button
                      variant="outline-primary"
                      class="btn-icon mr-1"
                      v-b-modal="`modal-edit${props.index}`"
                      @click="edi_data_row(props.row)"
                    >
                      <feather-icon icon="EditIcon" size="16" class="mx-1" />
                    </b-button>
                  </span>
                  <!-- basic modal -->
                  <b-modal
                    :id="`modal-edit${props.index}`"
                    title="แก้ไฃรับเข้าทรัพย์สิน"
                    cancel-variant="outline-secondary"
                    ok-variant="gradient-success"
                    ok-title="บันทึก"
                    cancel-title="ยกเลิก"
                    centered
                    size="lg"
                    no-close-on-backdrop
                    @ok="edit_input(props.row)"
                    @close="ccc_data()"
                    @cancel="ccc_data()"
                  >
                    <b-card-text>
                      <!-- edit -->

                      <b-form>
                        <div>
                          <div class="row">
                            <b-form-group
                              class="col-12 col-md-6"
                              label-cols="3"
                              label-cols-lg="4"
                              label="รหัสทรัพย์สิน:"
                            >
                              <template v-slot:label> ชื่อทรัพย์สิน <span class="text-danger"> </span> </template>
                              <b-input
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                v-model="props.row.e_name"
                                placeholder="อายุการใช้งาน"
                                disabled
                              />
                            </b-form-group>
                            <b-form-group
                              class="col-12 col-md-6"
                              label-cols="3"
                              label-cols-lg="4"
                              label="รหัสทรัพย์สิน:"
                            >
                              <template v-slot:label> อายุการใช้งาน <span class="text-danger"> </span> </template>
                              <b-input type="number" v-model="props.row.e_lifetime" placeholder="อายุการใช้งาน" />
                            </b-form-group>
                            <b-form-group
                              class="col-12 col-md-6"
                              label-cols="3"
                              label-cols-lg="4"
                              label="รหัสทรัพย์สิน:"
                            >
                              <template v-slot:label>
                                รหัสทรัพย์สิน
                                <span class="text-danger"> *</span>
                              </template>

                              <b-input id="idg" type="text" v-model="props.row.equipment_code" disabled />
                            </b-form-group>

                            <b-form-checkbox
                              name="check-button"
                              v-model="depreciation"
                              checked="true"
                              switch
                              inline
                            />คำนวนค่าเสือม

                            <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="ปีงบประมาณ:">
                              <template v-slot:label> ปีงบประมาณ <span class="text-danger"> *</span> </template>
                              <b-form-input
                                id="ids"
                                v-model="props.row.budget_year"
                                type="number"
                                maxlength="4"
                                placeholder="ปีงบประมาณ"
                                disabled
                              />
                            </b-form-group>
                          </div>
                          <div class="row">
                            <b-form-group class="col-md-12" label-cols="3" label-cols-lg="2" label="รายละเอียด:">
                              <template v-slot:label> รายละเอียด <span class="text-danger"> *</span> </template>

                              <b-form-textarea id="detall" v-model="props.row.information" placeholder="รายละเอียด" />
                            </b-form-group>
                          </div>
                          <b-row>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="จำนวน:">
                              <template v-slot:label> จำนวน <span class="text-danger"> *</span> </template>

                              <b-form-input disabled id="quantity" v-model="getfrom.quantity" placeholder="จำนวน" />
                            </b-form-group>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="หน่วยนับ:">
                              <template v-slot:label> หน่วยนับ <span class="text-danger"> *</span> </template>
                              <v-select
                                v-model="props.row.unittype"
                                placeholder="เลือกหน่วยนับ"
                                label="name"
                                :options="units"
                              />
                            </b-form-group>
                          </b-row>
                          <div class="row">
                            <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคา:">
                              <template v-slot:label>
                                ราคาจำนวนเต็ม
                                <span class="text-danger"> </span>
                              </template>

                              <b-input
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                v-model="unit_price"
                                class="form-control"
                                placeholder="ราคา (จำนวนเต็ม)"
                                @change="max_unit_price"
                              />
                            </b-form-group>
                            <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาราคาต่อหน่วย:">
                              <template v-slot:label> ราคาต่อหน่วย <span class="text-danger"> *</span> </template>

                              <b-form-input
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                v-model="getfrom.perunit"
                                class="form-control"
                                placeholder="ราคา (ราคาต่อหน่วย)"
                                @change="unitPrice"
                              />
                            </b-form-group>
                          </div>
                          <div class="row">
                            <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label=" ราคาจำนวนเต็มรวม VAT:">
                              <template v-slot:label>
                                ราคาจำนวนเต็มรวม VAT <span class="text-danger"> </span>
                              </template>
                              <span v-if="vat_switch === true">
                                <b-form-input
                                  v-model="getfrom.perunitvat"
                                  placeholder=" ราคาจำนวนเต็มรวม VAT"
                                  disabled
                                />
                              </span>
                              <span v-else>
                                <b-form-input
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  v-model="unit_price"
                                  class="form-control"
                                  placeholder="ราคา (จำนวนเต็ม)"
                                  disabled
                                />
                              </span>
                            </b-form-group>
                            <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาต่อหน่วย VAT:">
                              <template v-slot:label> ราคาต่อหน่วย VAT <span class="text-danger"> *</span> </template>
                              <!-- {{props.row.unitprice_vat}} -->
                              <span v-if="vat_switch === true">
                                <b-form-input
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  v-model="unit_price_vat"
                                  @change="vat_chan"
                                  placeholder="ราคารวม VAT"
                                />
                              </span>
                              <span v-else>
                                <b-form-input v-model="getfrom.perunit" placeholder="ราคารวม VAT" disabled />
                              </span>
                            </b-form-group>
                            <div class="col-6"></div>
                            <b-form-group class="col-6">
                              <b-form-checkbox
                                name="check-button"
                                v-model="vat_switch"
                                checked="true"
                                switch
                                inline
                                @change="get_no_sum_vat(vat_switch)"
                              />ราคาต่อหน่วยรวม VAT
                            </b-form-group>
                          </div>

                          <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
                            <v-select
                              v-model="workG"
                              placeholder="เลือกกลุ่มงาน"
                              label="work_gname"
                              :options="workGs"
                            />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="ผู้เบิก:">
                            <v-select
                              label="firstname"
                              v-model="props.row.response_person"
                              placeholder="ผู้เบิก"
                              :options="alluser"
                            />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="รหัส GF:">
                            <b-form-input v-model="props.row.gf_code" placeholder="รหัส GF" />
                          </b-form-group>
                          <div class="row">
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="เลขทะเบียน:">
                              <template v-slot:label> เลขทะเบียน <span class="text-danger"> *</span> </template>

                              <b-form-input
                                id="registration"
                                v-model="props.row.equipment_code"
                                placeholder="AUTO"
                                disabled
                              />
                            </b-form-group>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="วันที่รับ:">
                              <template v-slot:label> วันที่รับ <span class="text-danger"> *</span> </template>

                              <v-date-picker
                                id="example-datepicker3"
                                label="วันที่รับ:"
                                locale="th-TH"
                                v-model="date11"
                                @update:from-page="getHeaderInputDate"
                              >
                                <template v-slot="{ inputValue, inputEvents }">
                                  <b-form-input :value="formatDate(inputValue, 'date1')" v-on="inputEvents" />
                                </template>
                                <template #header-title>
                                  {{ headerInputDate }}
                                </template>
                              </v-date-picker>
                            </b-form-group>
                          </div>

                          <b-form-group label-cols="3" label-cols-lg="2" label="วิธีการจัดหา:">
                            <template v-slot:label> วิธีการจัดหา <span class="text-danger"> *</span> </template>

                            <v-select
                              v-model="props.row.supply_by"
                              placeholder="เลือกวิธีการจัดหา"
                              label="supply"
                              :options="procurement"
                            />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทเงิน:">
                            <template v-slot:label> ประเภทเงิน <span class="text-danger"> *</span> </template>

                            <v-select
                              v-model="props.row.budget_type"
                              placeholder="เลือกประเภทเงิน"
                              label="money_type"
                              :options="moneytypes"
                            />
                          </b-form-group>
                          <div class="row">
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="สัญญาเลขที่:"
                              ><template v-slot:label> เลขที่สัญญา<span class="text-danger"> </span> </template>
                              <b-form-input
                                id="voucher"
                                v-model="props.row.important_number"
                                placeholder="สัญญาเลขที่"
                              />
                            </b-form-group>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="ลงวันที่:"
                              ><template v-slot:label> ลงวันที่ <span class="text-danger"> </span> </template>

                              <v-date-picker
                                id="example-datepicker3"
                                label="ลงที่รับ:"
                                locale="th-TH"
                                v-model="date22"
                                @update:from-page="getHeaderInputDate"
                              >
                                <template v-slot="{ inputValue, inputEvents }">
                                  <b-form-input
                                    :value="formatDate(inputValue, 'date2')"
                                    v-on="inputEvents"
                                    placeholder="ลงวันที่"
                                  />
                                </template>
                                <template #header-title>
                                  {{ headerInputDate }}
                                </template>
                              </v-date-picker>
                            </b-form-group>
                          </div>
                          <div class="row">
                            <b-form-group
                              class="col-md-6"
                              label-cols="3"
                              label-cols-lg="4"
                              label="เลขที่ใบส่งของ/ใบกำกับภาษี:"
                              ><template v-slot:label>
                                เลขที่ใบส่งของ/ใบกำกับภาษี
                                <span class="text-danger"> </span>
                              </template>
                              <b-form-input
                                placeholder="เลขที่ใบส่งของ/ใบกำกับภาษี"
                                v-model="props.row.invoice_number"
                              />
                            </b-form-group>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="ลงวันที่:"
                              ><template v-slot:label> ลงวันที่ <span class="text-danger"> </span> </template>
                              <v-date-picker
                                id="example-datepicker3"
                                label="ลงที่รับ:"
                                locale="th-TH"
                                v-model="date33"
                                @update:from-page="getHeaderInputDate"
                              >
                                <template v-slot="{ inputValue, inputEvents }">
                                  <b-form-input
                                    :value="formatDate(inputValue, 'date3')"
                                    v-on="inputEvents"
                                    placeholder="ลงวันที่"
                                  />
                                </template>
                                <template #header-title>
                                  {{ headerInputDate }}
                                </template>
                              </v-date-picker>
                            </b-form-group>
                          </div>

                          <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทหลักฐาน:">
                            <template v-slot:label> ประเภทหลักฐาน <span class="text-danger"> </span> </template>
                            <v-select
                              placeholder="ประเภทหลักฐาน"
                              :v-model="props.row.evidence_type ? props.row.evidence_type : ''"
                              label="evidence"
                              :options="evidenceTypes"
                            />
                          </b-form-group>

                          <b-modal
                            id="modal-seller"
                            title="เลือกผู้ขาย"
                            cancel-title="ยกเลิก"
                            ok-title="ตกลง"
                            size="xl"
                          >
                            <b-card-text>
                              <b-form-group class="col-12 col-md-6">
                                <div class="d-flex align-items-center">
                                  <label class="mr-1">ค้นหา</label>
                                  <b-form-input
                                    v-model="searchSeller"
                                    placeholder="ค้นหา"
                                    type="text"
                                    class="d-inline-block"
                                  />
                                </div>
                              </b-form-group>
                              <vue-good-table
                                :columns="vender_columns"
                                :rows="vender_rows"
                                :search-options="{
                                  enabled: true,
                                  externalQuery: searchSeller,
                                }"
                                :select-options="{
                                  enabled: true,
                                  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                                  selectionInfoClass: 'custom-class',
                                  selectionText: 'rows selected',
                                  clearSelectionText: 'clear',
                                  disableSelectInfo: true, // disable the select info panel on top
                                  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                                }"
                                :pagination-options="{
                                  enabled: true,
                                  perPage: pageLength_vender,
                                }"
                                @on-row-click="onRowClick"
                              >
                                <!-- pagination -->
                                <template slot="pagination-bottom" slot-scope="props">
                                  <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                      <span class="text-nowrap"> Showing 1 to </span>
                                      <b-form-select
                                        v-model="pageLength_vender"
                                        :options="['5', '10', '15', '25', '50', '100']"
                                        class="mx-1"
                                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                                      />
                                      <span class="text-nowrap"> of {{ total_vender }} entries </span>
                                    </div>
                                    <div>
                                      <b-pagination
                                        :value="1"
                                        :total-rows="total_vender"
                                        :per-page="pageLength_vender"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value) => props.pageChanged({ currentPage: value })"
                                      >
                                        <template #prev-text>
                                          <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                          <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                      </b-pagination>
                                    </div>
                                  </div>
                                </template>
                              </vue-good-table>
                            </b-card-text>
                          </b-modal>
                          <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อผู้ขาย:">
                            <template v-slot:label>
                              ชื่อผู้ขาย/รับจ้าง
                              <span class="text-danger"> </span>
                            </template>
                            <b-form-input v-model="props.row.vender" placeholder="ชื่อผู้ขาย/รับจ้าง" />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="เบอร์โทร:">
                            <template v-slot:label> เบอร์โทร <span class="text-danger"> </span> </template>
                            <b-form-input
                              v-model="props.row.vender_phone"
                              placeholder="เบอร์โทร"
                              maxlength="10"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="แฟกซ์:">
                            <template v-slot:label> แฟกซ์ <span class="text-danger"> </span> </template>
                            <b-form-input
                              v-model="props.row.vender_fax"
                              placeholder="แฟกซ์"
                              maxlength="10"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="ที่อยู่ผู้ขาย/รับจ้าง:">
                            <template v-slot:label>
                              ที่อยู่ผู้ขาย/รับจ้าง
                              <span class="text-danger"> </span>
                            </template>
                            <b-form-textarea v-model="props.row.vender_address" placeholder="ที่อยู่ผู้ขาย/รับจ้าง" />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="อีเมล:">
                            <template v-slot:label> อีเมล <span class="text-danger"> </span> </template>
                            <b-form-input v-model="props.row.vender_email" placeholder="อีเมล" />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="หมายเลขภาษี:">
                            <template v-slot:label> หมายเลขภาษี <span class="text-danger"> </span> </template>
                            <b-form-input v-model="props.row.vender_taxnumber" placeholder="หมายเลขภาษี" />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="รูปภาพ:">
                            <b-form-file
                              class="mb-1"
                              v-model="file1"
                              placeholder="เลือกไฟล์รูปภาพ"
                              accept="image/*"
                              drop-placeholder="Drop file here..."
                              multiple
                            />
                          </b-form-group>
                          <div class="row">
                            <span class="ml-2"></span>
                            <span class="ml-5"></span>
                          </div>
                        </div>
                      </b-form>
                    </b-card-text>
                  </b-modal>
                </span>

                <span v-else-if="props.column.field === 'จำนวน'">
                  <div class="text-right">{{ props.row.จำนวน }}</div>
                </span>
                <span v-else-if="props.column.field === 'ราคาต่อหน่วย'">
                  <div class="text-right">{{ props.row.ราคาต่อหน่วย }}</div>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div>
      <validation-observer ref="observer" v-slot="{ passes }">
        <b-modal
          id="modal-1"
          cancel-variant="outline-secondary"
          ok-variant="gradient-success"
          ok-title="บันทึก"
          cancel-title="ยกเลิก"
          centered
          title="รับเข้าทรัพย์สิน"
          size="lg"
          @ok.prevent="passes(postdata)"
          @cancel="cancel()"
          @close="cancel()"
          no-close-on-backdrop
        >
          <!-- cope -->
          <b-form>
            <div>
              <div class="row">
                <b-form-group
                  v-if="instan === 'on'"
                  class="col-12"
                  label-cols="3"
                  label-cols-lg="2"
                  label="เลขทะเบียน:"
                >
                  <v-select
                    v-model="registration_number"
                    label="equipment_code"
                    :options="registration_numbers"
                    placeholder="เลือกเลขทะเบียน"
                    @input="registration_(registration_number)"
                  />
                </b-form-group>

                <b-form-group class="col-12" label-cols="3" label-cols-lg="2" label="กลุ่มทรัพย์สิน:">
                  <template v-slot:label> กลุ่มทรัพย์สิน <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      v-model="selectedG"
                      label="e_gname"
                      @input="changeRoute($event)"
                      :options="selectedGs"
                      placeholder="เลือกกลุ่มกลุ่มทรัพย์สิน"
                      :state="errors.length > 0 ? false : null"
                    />
                    <div v-show="errors != ''">
                      <p class="text-danger">เลือกกลุ่มกลุ่มทรัพย์สิน</p>
                    </div>
                  </validation-provider>
                </b-form-group>
                <div></div>
                <b-form-group class="col-12" label-cols="3" label-cols-lg="2" label="ชื่อทรัพย์สิน:">
                  <template v-slot:label> ชื่อทรัพย์สิน <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      :state="errors.length > 0 ? false : null"
                      v-model="getfrom.typeG"
                      label="e_id_e_name"
                      :options="typeGs"
                      placeholder="เลือกชื่อทรัพย์สิน"
                      @input="lit_G"
                    />
                    <div v-show="errors != ''">
                      <p class="text-danger">เลือกชื่อทรัพย์สิน</p>
                    </div>
                    <!-- aaa -->
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="รหัสทรัพย์สิน:">
                  <template v-slot:label> อายุการใช้งาน <span class="text-danger"> </span> </template>

                  <b-input type="number" v-model="e_lifetime" placeholder="อายุการใช้งาน" />
                </b-form-group>
                <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="รหัสทรัพย์สิน:">
                  <template v-slot:label> รหัสทรัพย์สิน <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-input
                      id="idg"
                      type="text"
                      :value="
                        (selectedG && getfrom ? selectedG.e_gid + '-' + getfrom.typeG.e_id : '')
                          .replace('-undefined', '')
                          .replace('-undefined', '')
                      "
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-12 col-md-6" label-cols="3" label-cols-lg="4" label="ปีงบประมาณ:">
                  <template v-slot:label> ปีงบประมาณ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="ids"
                      v-model="getfrom.idg"
                      type="text"
                      maxlength="4"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      placeholder="ปีงบประมาณ"
                    />
                  </validation-provider>
                </b-form-group>

                <b-form-checkbox name="check-button" v-model="depreciation" checked="true" switch inline />คำนวนค่าเสือม
              </div>
              <div class="row">
                <!-- <b-form-group
                class="col-md-12"
                label-cols="3"
                label-cols-lg="2"
                label="ชื่อทรัพย์สิน:"
              >
                <b-form-input
                  v-model="getfrom.typeG.name"
                  type="text"
                  placeholder="ชื่อทรัพย์สิน"
                />
              </b-form-group> -->
                <b-form-group class="col-md-12" label-cols="3" label-cols-lg="2" label="รายละเอียด:">
                  <template v-slot:label> รายละเอียด <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-textarea
                      :state="errors.length > 0 ? false : null"
                      id="detall"
                      v-model="getfrom.information"
                      placeholder="รายละเอียด"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <b-row>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="จำนวน:">
                  <template v-slot:label> จำนวน <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      id="quantity"
                      v-model="getfrom.quantity"
                      placeholder="จำนวน"
                    />
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="หน่วยนับ:">
                  <template v-slot:label> หน่วยนับ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      :state="errors.length > 0 ? false : null"
                      id="unit"
                      v-model="getfrom.unit"
                      placeholder="เลือกหน่วยนับ"
                      label="name"
                      :options="units"
                    />
                    <div v-show="errors != ''">
                      <p class="text-danger">เลือกหน่วยนับ</p>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-row>
              <div class="row">
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคา:">
                  <template v-slot:label> ราคาจำนวนเต็ม <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors.length > 0 ? false : null"
                      v-model="unit_price"
                      class="form-control"
                      placeholder="ราคา (จำนวนเต็ม)"
                      @change="max_unit_price"
                    />
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาราคาต่อหน่วย:">
                  <template v-slot:label> ราคาต่อหน่วย <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors.length > 0 ? false : null"
                      v-model="getfrom.perunit"
                      class="form-control"
                      placeholder="ราคา (ราคาต่อหน่วย)"
                      id="unit"
                      @change="unitPrice"
                    />
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label="ราคาจำนวนเต็มรวม VAT:">
                  <template v-slot:label> ราคาจำนวนเต็มรวม VAT <span class="text-danger"> *</span> </template>
                  <span v-if="vat_switch === true">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-input
                        class="form-control"
                        :raw="false"
                        :options="number"
                        :state="errors.length > 0 ? false : null"
                        id="unit"
                        v-model="getfrom.perunitvat"
                        placeholder="ราคาจำนวนเต็มรวม VAT"
                        disabled
                      />
                    </validation-provider>
                  </span>
                  <span v-else>
                    <b-input v-model="unit_price" disabled />
                  </span>
                </b-form-group>
                <b-form-group class="col-6" label-cols="3" label-cols-lg="4" label=" ราคาต่อหน่วย VAT :">
                  <template v-slot:label> ราคาต่อหน่วย VAT <span class="text-danger"> *</span> </template>
                  <span v-if="vat_switch === true">
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-input
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        class="form-control"
                        :raw="false"
                        :options="number"
                        :state="errors.length > 0 ? false : null"
                        id="unit"
                        v-model="unit_price_vat"
                        placeholder=" ราคาต่อหน่วย VAT "
                        @change="vat_chan"
                      />
                    </validation-provider>
                  </span>
                  <span v-else>
                    <b-input
                      class="form-control"
                      :raw="false"
                      :options="number"
                      id="unit"
                      placeholder=" ราคาต่อหน่วย VAT "
                      v-model="getfrom.perunit"
                      disabled
                    />
                  </span>
                </b-form-group>
                <b-form-group class="col-6">
                  <!-- <b-form-checkbox
                    name="check-button"
                    v-model="vat_switch"
                    checked="true"
                    switch
                    inline
                  />ราคาต่อหน่วยรวม VAT -->
                </b-form-group>
                <b-form-group class="col-6">
                  <b-form-checkbox
                    name="check-button"
                    v-model="vat_switch"
                    checked="true"
                    switch
                    inline
                    @change="get_no_sum_vat(vat_switch)"
                  />ราคาต่อหน่วยรวม VAT
                </b-form-group>
              </div>

              <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
                <template v-slot:label> กลุ่มงาน <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    v-model="workG"
                    label="work_gname"
                    placeholder="เลือกกลุ่มงาน"
                    :options="workGs"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกกลุ่มงาน</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ผู้เบิก:">
                <template v-slot:label> ผู้เบิก <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    :options="alluser"
                    label="firstname"
                    :state="errors.length > 0 ? false : null"
                    v-model="getfrom.voucher"
                    placeholder="ผู้เบิก"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกผู้เบิก</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="รหัส GF:">
                <b-form-input v-model="getfrom.gf_code" placeholder="รหัส GF" />
              </b-form-group>
              <div class="row">
                <!-- เลขทะเบียน -->
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="เลขทะเบียน:">
                  <template v-slot:label> เลขทะเบียน <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="registration"
                      v-model="getfrom.registration"
                      placeholder="AUTO"
                      disabled
                    />
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="วันที่รับ:">
                  <template v-slot:label> วันที่รับ <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-date-picker
                      id="example-datepicker"
                      label="วันที่รับ:"
                      locale="th-TH"
                      v-model="date1"
                      @update:from-page="getHeaderInputDate"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <b-form-input
                          :value="formatDate(inputValue, 'date1')"
                          v-on="inputEvents"
                          :state="errors.length > 0 ? false : null"
                        />
                      </template>
                      <template #header-title>
                        {{ headerInputDate }}
                      </template>
                    </v-date-picker>
                  </validation-provider>
                </b-form-group>
              </div>

              <b-form-group label-cols="3" label-cols-lg="2" label="วิธีการจัดหา:">
                <template v-slot:label> วิธีการจัดหา <span class="text-danger"> *</span> </template>

                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    v-model="getfrom.provided"
                    placeholder="เลือกวิธีการจัดหา"
                    label="supply"
                    :options="procurement"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกวิธีการจัดหา</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทเงิน:">
                <template v-slot:label> ประเภทเงิน <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    v-model="moneytype"
                    placeholder="เลือกประเภทเงิน"
                    label="money_type"
                    :options="moneytypes"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกประเภทเงิน</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <div class="row">
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="สัญญาเลขที่:"
                  ><template v-slot:label> เลขที่สัญญา<span class="text-danger"> </span> </template>

                  <b-form-input id="voucher" v-model="getfrom.voucherx" placeholder="สัญญาเลขที่" />
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="ลงวันที่:"
                  ><template v-slot:label> ลงวันที่ <span class="text-danger"> </span> </template>

                  <v-date-picker
                    id="example-datepicker2"
                    label="วันที่รับ:"
                    locale="th-TH"
                    v-model="date2"
                    @update:from-page="getHeaderInputDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <b-form-input
                        :value="formatDate(inputValue, 'date2')"
                        v-on="inputEvents"
                        placeholder="ลงวันที่"
                      />
                    </template>
                    <template #header-title>
                      {{ headerInputDate }}
                    </template>
                  </v-date-picker>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="เลขที่ใบส่งของ/ใบกำกับภาษี:"
                  ><template v-slot:label>
                    เลขที่ใบส่งของ/ใบกำกับภาษี
                    <span class="text-danger"> </span>
                  </template>

                  <b-form-input placeholder="เลขที่ใบส่งของ/ใบกำกับภาษี" v-model="getfrom.delivery_invoice" />
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="ลงวันที่:"
                  ><template v-slot:label> ลงวันที่ <span class="text-danger"> </span> </template>
                  <v-date-picker
                    id="example-datepicker3"
                    label="วันที่รับ:"
                    locale="th-TH"
                    v-model="date3"
                    @update:from-page="getHeaderInputDate"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <b-form-input
                        :value="formatDate(inputValue, 'date3')"
                        v-on="inputEvents"
                        placeholder="ลงวันที่"
                      />
                    </template>
                    <template #header-title>
                      {{ headerInputDate }}
                    </template>
                  </v-date-picker>
                </b-form-group>
              </div>
              <!-- <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="เลขที่เอกสาร:"
            >
              <template v-slot:label>
                เลขที่เอกสาร <span class="text-danger"> *</span>
              </template>
              <b-form-input
                id="voucherx"
                v-model="getfrom.voucherx"
                placeholder="เลขที่เอกสาร"
              />
            </b-form-group> -->
              <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทหลักฐาน:">
                <template v-slot:label> ประเภทหลักฐาน <span class="text-danger"> </span> </template>

                <v-select
                  placeholder="ประเภทหลักฐาน"
                  v-model="getfrom.selected"
                  label="evidence"
                  :options="evidenceTypes"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ประเภทการขาย:">
                <template v-slot:label> ประเภทการขาย <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <v-select
                    :state="errors.length > 0 ? false : null"
                    placeholder="ประเภทการขาย"
                    label="v_gname"
                    v-model="salesType"
                    :options="salesTypes"
                    @input="cc_salesType"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">ประเภทการขาย</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-button v-b-modal.modal-seller> เลือกผู้ขาย </b-button>
              <!-- basic modal -->
              <b-modal id="modal-seller" title="เลือกผู้ขาย" cancel-title="ยกเลิก" ok-title="ตกลง" size="xl">
                <b-card-text>
                  <b-form-group class="col-12 col-md-6">
                    <div class="d-flex align-items-center">
                      <label class="mr-1">ค้นหา</label>
                      <b-form-input v-model="searchSeller" placeholder="ค้นหา" type="text" class="d-inline-block" />
                    </div>
                  </b-form-group>

                  <vue-good-table
                    :columns="vender_columns"
                    :rows="vender_rows"
                    :search-options="{
                      enabled: true,
                      externalQuery: searchSeller,
                    }"
                    :select-options="{
                      enabled: true,
                      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                      selectionInfoClass: 'custom-class',
                      selectionText: 'rows selected',
                      clearSelectionText: 'clear',
                      disableSelectInfo: true, // disable the select info panel on top
                      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage: pageLength_vender,
                    }"
                    @on-row-click="onRowClick"
                  >
                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap"> Showing 1 to </span>
                          <b-form-select
                            v-model="pageLength_vender"
                            :options="['5', '10', '15', '25', '50', '100']"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                          />
                          <span class="text-nowrap"> of {{ total_vender }} entries </span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="total_vender"
                            :per-page="pageLength_vender"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </b-card-text>
              </b-modal>
              <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อผู้ขาย:">
                <template v-slot:label> ชื่อผู้ขาย/รับจ้าง <span class="text-danger"> *</span> </template>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="getfrom.vender"
                    placeholder="ชื่อผู้ขาย/รับจ้าง"
                  />
                  <div v-show="errors != ''">
                    <p class="text-danger">เลือกชื่อผู้ขาย/รับจ้าง</p>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="เบอร์โทร:">
                <template v-slot:label> เบอร์โทร <span class="text-danger"> </span> </template>

                <b-form-input
                  type="text"
                  v-model="getfrom.vender_phone"
                  placeholder="เบอร์โทร"
                  maxlength="10"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="แฟกซ์:">
                <template v-slot:label> แฟกซ์ <span class="text-danger"> </span> </template>

                <b-form-input
                  type="text"
                  v-model="getfrom.vender_fax"
                  placeholder="แฟกซ์"
                  maxlength="10"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="ที่อยู่ผู้ขาย/รับจ้าง:">
                <template v-slot:label> ที่อยู่ผู้ขาย/รับจ้าง <span class="text-danger"> </span> </template>

                <b-form-textarea v-model="getfrom.vender_address" placeholder="ที่อยู่ผู้ขาย/รับจ้าง" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="อีเมล:">
                <template v-slot:label> อีเมล <span class="text-danger"> </span> </template>

                <b-form-input v-model.email="getfrom.vender_email" placeholder="อีเมล" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="หมายเลขภาษี:">
                <template v-slot:label> หมายเลขภาษี <span class="text-danger"> </span> </template>

                <b-form-input v-model="getfrom.vender_taxnumber" placeholder="หมายเลขภาษี" />
              </b-form-group>
              <b-form-group label-cols="3" label-cols-lg="2" label="รูปภาพ:">
                <b-form-file
                  class="mb-1"
                  accept="image/*"
                  v-model="file1"
                  placeholder="เลือกไฟล์รูปภาพ"
                  drop-placeholder="Drop file here..."
                  multiple
                />
              </b-form-group>

              <div class="row">
                <span class="ml-2"></span>
                <span class="ml-5"></span>
                <b-button variant="success" class="ml-5" @click="passes(arrayPost)">เพิ่ม</b-button>

                <!-- <span class="ml-5">ผู้สร้าง : <span>test</span></span>
                <span class="ml-5">วันที่ : <span>01/01/2565</span></span>
                <span class="ml-5">ผู้แก้ไข : <span>test</span></span>
                <span class="ml-5">วันที่แก้ไข : <span>01/01/2565</span></span> -->
              </div>

              <div v-for="arrdata in arrdata" :key="arrdata">
                <b-list-group-item class="d-flex justify-content-between align-items-center">
                  <!-- <b-badge variant="primary" pill class="badge-round">
                      {{number=number+1}}
                      {{ arrdata.number}}
                    </b-badge> -->
                  <span class="row justify-content-around">
                    <div style="color: #336bff">
                      ชื่อทรัพย์สิน:
                      <span style="color: #000000"> {{ arrdata.e_name }}</span>
                    </div>
                    <div style="color: #336bff">
                      <span> &nbsp;</span>
                      อายุการใช้งาน:
                      <span style="color: #000000"> {{ arrdata.e_lifetime }} ปี</span>
                    </div>
                    <div style="color: #336bff">
                      <span> &nbsp;</span>
                      จำนวน:
                      <span style="color: #000000"> {{ arrdata.amount }} {{ arrdata.unittype }}</span>
                    </div>
                    <div style="color: #336bff">
                      <span> &nbsp;</span>
                      ผู้เบิก:
                      <span style="color: #000000">
                        {{ arrdata.response_person }}
                      </span>
                    </div>
                  </span>
                </b-list-group-item>
              </div>
            </div>
          </b-form>
          <!-- cope -->
        </b-modal>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { BCardText } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { DatePicker } from "v-calendar";

export default {
  components: {
    ToastificationContent,
    required,
    ValidationObserver,
    ValidationProvider,
    Cleave,
    BCardText,
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
    DatePicker,
  },

  data() {
    const date = new Date();
    return {
      searchSeller: "",
      headerInputDate: "",
      date1: new Date(),
      date2: "",
      date3: "",
      date11: new Date(),
      date22: "",
      date33: "",
      value_unitprice_vat: "",
      max_value: "",
      max_value_vat: "",
      registration_number: "",
      registration_numbers: [],
      instan: "off",
      unit_price_vat: "",
      e_lifetime: "",
      file1: null,
      property_code: "",
      disabled: true,
      select_vat: "NO VAT",
      vat_options: [
        { text: "รวม VAT", value: "VAT" },
        { text: "ไม่รวม VAT", value: "NO VAT" },
      ],
      show: false,
      codea: "",
      vat: null,
      value: null,
      locale: "th-Th",
      price: null,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      selectedG: "",
      selectedGs: [],
      getfrom: {
        typeG: "",
        idg: "",
        information: "",
        ids: "",
        name: "",
        detall: "",
        quantity: "",
        unit: "",
        perunit: "",
        perunitvat: "",
        agency: "",
        registration: "",
        provided: "",
        money: "",
        voucher: "",
        voucherx: "",
        voucherx_date: "",
        delivery_invoice: "",
        delivery_invoice_date: "",
        gf_code: "",
        admitdate: "",
        selected: "",
        vender: "",
        vender_address: "",
        vender_phone: "",
        vender_fax: "",
        vender_email: "",
        vender_taxnumber: "",
      },
      equipment_code_gen: "",
      unit_price: "",
      salesType: "",
      salesTypes: [],
      pageLength: 50,
      currentPage: 1,
      total: "",
      dir: false,
      moneytype: null,
      moneytypes: [],
      procurement: [],
      workG: "",
      workGs: [],

      typeGs: [],
      selected: "",
      evidenceTypes: [],
      columns: [
        {
          label: "วันที่-เดือน-ปี",
          field: "admitdate",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา วันที่-เดือน-ปี",
          },
        },
        {
          label: "เลขทะเบียน",
          field: "equipment_code",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา เลขทะเบียน",
          },
        },
        {
          label: "รหัสทรัพย์สิน",
          field: "e_gid_e_id",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา รหัสทรัพย์สิน",
          },
        },
        {
          label: "ชื่อทรัพย์สิน",
          field: "e_name",
          thClass: "text-center",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ชื่อทรัพย์สิน",
          },
        },
        {
          label: "ผู้เบิก",
          field: "response_person",
          thClass: "text-center",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ผู้เบิก",
          },
        },

        {
          label: "หน่วยนับ",
          field: "unittype",

          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา หน่วยนับ",
          },
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          type: "number",
          width: "130px",
          sortable: true,
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ราคาต่อหน่วย",
          },
        },
        {
          label: "ราคาต่อหน่วย + vat7%",
          field: "unitprice_vat",
          type: "number",
          width: "190px",
          sortable: true,
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา ราคาต่อหน่วย + vat7%",
          },
        },
        {
          label: "จำนวน",
          field: "amount",
          filterOptions: {
            enabled: true,
            placeholder: "ค้นหา จำนวน",
          },
        },

        {
          label: "รายการรับเข้า",
          field: "ดูรายการ",
          thClass: "text-center",
          tdClass: "text-center",
          width: "150px",
        },
        {
          label: "แก้ไข",
          field: "edit",
          thClass: "text-center",
          tdClass: "text-center",
          width: "5%",
        },
        {
          label: "ลบ",
          field: "delete",
          thClass: "text-center",
          tdClass: "text-center",
          width: "5%",
        },
      ],
      rows: [],
      arrdata: [],
      searchTerm: "",
      units: [],
      alluser: [],
      vender_rows: [],
      total_vender: "",
      pageLength_vender: 15,
      _page_vender: 1,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      vender_columns: [
        {
          label: "ID",
          field: "v_id",
        },
        {
          label: "ชื่อผู้ขาย/รับจ้าง",
          field: "v_name",
        },
        {
          label: "ที่อยู่ผู้ขาย/รับจ้าง",
          field: "v_address",
        },
        {
          label: "เบอร์โทรผู้ขาย",
          field: "v_telephone",
        },
        {
          label: "แฟกซ์ผู้ขาย",
          field: "v_fax",
        },
        {
          label: "email",
          field: "v_email",
        },
        {
          label: "หมายเลขภาษี",
          field: "v_taxnumber",
        },
      ],
      chang_unitprice: "",
      chang_unitprice_vat: "",
      vat_switch: true,
      depreciation: true,
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    this.ecode();
    this.get_unit();
    this.equipmentg();
    this.getdatatable();

    this.get_data_table();
    this.supplyBy();
    this.s_moneytypes();
    this.s_evidenceTypes();
    this.s_salesTypes();
    // this.checkLogout();
    const { access_token } = await this.access_token();
    const workgroups = `${API}workgroups?_page=1&_limit=100&_sort=1`;
    const header_workgroups = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res_workgroups = await axios.get(workgroups, header_workgroups);
    // console.log(res_workgroups);
    const res_amp = res_workgroups.data.message.data.map((element) => {
      return {
        ...element,
        work_gname: `${element.work_gid} - ${element.work_gname}`,
      };
    });
    this.workGs = res_amp;
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },
    ccc_data() {
      console.log("asdsadsadsadsadsadsadasdsad");
      this.workG = "";
      this.getfrom.voucher = "";
      this.getfrom.quantity = "";
      this.getfrom.perunit = "";
      this.depreciation = true;
      this.unit_price = "";
      this.vat_switch = true;
      this.getfrom.perunitvat = "";
      location.reload();
    },
    async cancel() {
      const { access_token_eg } = await this.access_token();
      const head_eg = {
        headers: {
          Authorization: await access_token_eg,
        },
      };
      const eg = `${API}cancelReservation?equipment_code=${this.registration_number}&e_id=${this.getfrom.typeG.e_id}`;
      const res_eg = await axios.get(eg, head_eg);
      console.log(res_eg.data.status);
      location.reload();
    },
    getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      // console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
            this.getfrom.admitdate = dete_val;
            break;
          case "date2":
            this.getfrom.voucherx_date = dete_val;
            break;
          case "date3":
            this.getfrom.delivery_invoice_date = dete_val;
            break;
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
    async edi_data_row(value) {
      console.log(value);
      const { access_token } = await this.access_token();
      const workgroups = `${API}workgroups?work_gid=${value.work_gid}&_page=1&_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);

      console.log(res_workgroups.data.message.data);
      [this.workG] = res_workgroups.data.message.data.map((el) => {
        return {
          ...el,
          work_gname: `${el.work_gid} - ${el.work_gname}`,
        };
      });
      this.depreciation = value.flag_calculate;
      this.getfrom.voucher = value.response_person;
      this.date11 = new Date(value.date_raw).toString();
      // console.log(value.amount);
      // console.log(value.unitprice);
      // console.log(value.unitprice_vat);
      this.getfrom.quantity = value.amount;
      this.getfrom.perunit = value.unitprice;
      this.unit_price_vat = value.unitprice_vat;
      this.unit_price = value.amount * value.unitprice;
      this.getfrom.perunitvat = (Number(this.unit_price) * 7) / 100 + this.unit_price;
      if (this.unit_price_vat == this.unit_price) {
        this.vat_switch = false;
      }
      this.getfrom.perunitvat = Number(this.getfrom.perunitvat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unit_price = Number(this.unit_price)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unit_price_vat = Number(this.unit_price_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.getfrom.perunit = Number(this.getfrom.perunit)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    async s_data(value) {
      // console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}receiveAccess?equipment_code=${value}&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      this.rows = res.data.message.data.map((res) => {
        return {
          ...res,
          admitdate: `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]} ${
            Number(res.admitdate.split("-")[0]) + 543
          }`,
          m_image: res.m_image ? JSON.parse(res.m_image) : [],
        };
      });
    },
    registration_(value) {
      this.getfrom.registration = value;
    },
    async carray_register() {
      // this.checkLogout();
      this.instan = "on";
      this.registration_number = "";
      this.getfrom.registration = "";
      this.equipment_code_gen = "false";

      const url = `${API}receiveAccess?_limit=100&_page=1`;

      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.total);
      const res_total = Math.ceil(Number(res.data.message.total) / 100);
      console.log(res_total);

      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}receiveAccess?_page=${i}&_limit=100`);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], head)).data.message.data];
      }
      console.log(res_data_);
      const aa = res_data_;
      var b = [];

      var c = [];
      var cc = [];
      for (var i = 0; i < aa.length; i++) {
        // console.log(aa[i].equipment_code);

        if (b.indexOf(aa[i].equipment_code) < 0) {
          b.push(aa[i].equipment_code);
        } else {
          cc.push(aa[i].equipment_code);
          c.push(aa[i]);
        }
      }
      // console.log('Array ที่ลบค่าซ้ำ = ' + [b]);
      // console.log('Array เฉพาะค่าซ้ำ = ' + cc);
      this.registration_numbers = b;
      return;
      this.registration_numbers = res.data.message.data;
      //  console.log(this.registration_numbers)
      var pp = [];
      res.data.message.data.forEach((element, index) => {
        pp[index] = element.equipment_code;
      });

      var bb = [];
      var vv = "";
      var kk = 0;
      res.data.message.data.forEach((element, index) => {
        var aa = pp.indexOf(element.equipment_code);
        if (aa != vv) {
          bb[kk] = aa;
          kk++;
        }
        vv = aa;
      });
      console.log(bb);
      var bbx = [];
      bb.forEach((element, index) => {
        var aa = res.data.message.data[element];
        bbx[index] = aa;
      });
      console.log(bbx);
      this.registration_numbers = bbx;

      // this.registration_numbers = res.data.message.data;
      // registration_numbers
      //API
    },
    carray() {
      // this.checkLogout();
      this.instan = "off";
      this.equipment_code_gen = "true";
      this.registration_number = "";
      this.arrdata = [];
      this.ecode();
    },
    async lit_G() {
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      console.log(this.registration_number);
      console.log(this.getfrom.typeG.e_id);

      // const eg = `${API}reserveEquiptmentGroup?equipment_code=${this.registration_number}&e_id=${this.getfrom.typeG.e_id}`;
      // const res_eg = await axios.get(eg, head);
      // console.log(res_eg.data.status);
      // if (this.registration_number != '') {
      //   if (res_eg.data.status === 400) {
      //     Swal.fire({
      //       position: 'center',
      //       icon: 'error',
      //       title: 'กรุณาเลือกชื่อทรัพย์สินใหม่',
      //       text: ``,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });

      //     this.getfrom.typeG = ' ';
      //   }
      // }

      const url = `${API}readOldLifeTime?e_gid=${this.selectedG.e_gid}&e_id=${this.getfrom.typeG.e_id}`;

      const res = await axios.get(url, head);
      // console.log(res.data.message.data[0].e_lifetime);
      this.e_lifetime = res.data.message.data[0].e_lifetime;
    },
    async onRowClick(value) {
      // console.log(value.row);
      this.getfrom.vender = value.row.v_name;
      this.getfrom.vender_address = value.row.v_address;
      this.getfrom.vender_phone = value.row.v_telephone;
      this.getfrom.vender_fax = value.row.v_fax;
      this.getfrom.vender_email = value.row.v_email;
      this.getfrom.vender_taxnumber = value.row.v_taxnumber;
    },
    async cc_salesType(value) {
      console.log(this.salesType.v_gid);

      const url = `${API}venderInfo?v_gid=${this.salesType.v_gid}&_page=${this._page_vender}&_limit=100`;
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      // this.vender_rows = res.data.message.data;

      let res_pdf = [];
      const { total } = res.data.message;
      this.total_vender = total;
      let idx = Math.ceil(Number(total) / 100);
      for (let index = 1; index <= idx; index++) {
        console.log(index);
        const url_pdf = `${API}venderInfo?v_gid=${this.salesType.v_gid}&_page=${index}&_limit=100`;
        const res_pdfs = await axios.get(url_pdf, head);
        console.log(res_pdfs.data.message.data);

        res_pdf.push(...res_pdfs.data.message.data);
      }
      console.log(res_pdf);
      this.vender_rows = res_pdf;
    },
    perPageChanged_vender(value) {
      console.log(value);
      this.pageLength_vender = value.currentPerPage;
      this.cc_salesType();
    },
    pageChanged_vender(value) {
      console.log(value);
      this._page_vender = value.currentPage;
      this.cc_salesType();
    },
    async s_salesTypes() {
      const { access_token } = await this.access_token();
      const url = `${API}vendertype`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const cc = res.data.message.data.map((ss) => {
        return {
          ...ss,
          v_gname: `${ss.v_gid} - ${ss.v_gname}`,
        };
      });
      this.salesTypes = cc;
    },
    async s_evidenceTypes() {
      // this.evidenceTypes
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.evidenceTypes = res.data.message.data;
    },
    async s_moneytypes() {
      const { access_token } = await this.access_token();
      const url = `${API}moneyTypes`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.moneytypes = res.data.message.data;
    },
    async supplyBy() {
      const { access_token } = await this.access_token();
      const url = `${API}supplyBy`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.procurement = res.data.message.data;
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const res_total = Math.ceil(Number(res.data.message.total) / 100);

      const url_link = [];
      for (let i = 1; i <= res_total; i++) {
        url_link.push(`${API}user?_page=${i}&_limit=100`);
      }
      let res_data_ = [];
      for (let i in url_link) {
        res_data_ = [...res_data_, ...(await axios.get(url_link[i], head)).data.message.data];
      }
      console.log(res_data_);

      const res_user = res_data_.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
      // console.log(res.data.message);
    },
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    get_no_sum_vat(value) {
      if (this.vat_switch === false) {
        // this.unit_price_vat = this.unitprice;
      } else {
        // this.vat_chan();
      }
      // console.log(this.unitprice);
      // console.log(this.unit_price_vat);
    },
    vat_chan() {
      // console.log(this.unit_price_vat);
      const no_vat_a = (Number(this.unit_price_vat).toFixed(2) * 100) / 107;
      // console.log(no_vat_a.toFixed(2));
      // this.unitprice = Number(no_vat_a)
      //   .toFixed(2)
      //   .toString()
      //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      // this.unit_price =
      //   this.unitprice.replace(',', '').replace(',', '').replace(',', '') *
      //   this.amount.replace(',', '').replace(',', '').replace(',', '');
      // this.unitprice_vat = (Number(this.unit_price) * 7) / 100 + Number(this.unit_price);

      // this.unitprice_vat = Number(this.unitprice_vat)
      //   .toFixed(2)
      //   .toString()
      //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      // this.unit_price = Number(this.unit_price)
      //   .toFixed(2)
      //   .toString()
      //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      this.unit_price = Number(this.getfrom.quantity) * Number(no_vat_a);

      this.getfrom.perunitvat = Number(this.unit_price) + (Number(this.unit_price) * 7) / 100;

      this.getfrom.perunitvat = Number(this.getfrom.perunitvat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      this.unit_price = Number(this.unit_price)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.getfrom.perunit = Number(no_vat_a)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unit_price_vat = Number(this.unit_price_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    max_unit_price() {
      this.getfrom.perunit =
        Number(this.unit_price.replace(",", "").replace(",", "").replace(",", "")) / Number(this.getfrom.quantity);

      this.unit_price = Number(this.unit_price).toFixed(2);
      this.getfrom.perunit = Number(this.getfrom.perunit).toFixed(2);
      this.getfrom.perunit = this.getfrom.perunit.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.unit_price = this.unit_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      const no_vat_a = this.getfrom.perunit.replace(",", "").replace(",", "").replace(",", "");
      const gg_a = (Number(no_vat_a) * 7) / 100;

      const no_vat = this.unit_price.replace(",", "").replace(",", "").replace(",", "");
      const gg = (Number(no_vat) * 7) / 100;
      this.getfrom.perunitvat = (Number(no_vat) + Number(gg)).toFixed(2);

      if ((Number(no_vat_a) + Number(gg_a)).toFixed(2) === "Infinity") {
        this.unit_price_vat = 0;
      } else {
        this.unit_price_vat = (Number(no_vat_a) + Number(gg_a)).toFixed(2);
      }
      if (this.getfrom.perunit.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") === "Infinity") {
        this.getfrom.perunit = 0;
      } else {
        this.getfrom.perunit = this.getfrom.perunit.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    unitPrice() {
      this.unit_price = this.getfrom.quantity * this.getfrom.perunit.replace(",", "").replace(",", "").replace(",", "");

      this.unit_price = Number(this.unit_price).toFixed(2);
      this.getfrom.perunit = Number(this.getfrom.perunit).toFixed(2);
      this.unit_price = this.unit_price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.getfrom.perunit = this.getfrom.perunit.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

      const no_vat_a = this.getfrom.perunit.replace(",", "").replace(",", "").replace(",", "");
      const gg_a = (Number(no_vat_a) * 7) / 100;
      this.unit_price_vat = (Number(no_vat_a) + Number(gg_a)).toFixed(2);

      const no_vat = this.unit_price.replace(",", "").replace(",", "").replace(",", "");
      const gg = (Number(no_vat) * 7) / 100;
      this.getfrom.perunitvat = (Number(no_vat) + Number(gg)).toFixed(2);
      this.unit_price_vat = Number(this.unit_price_vat)
        .toFixed(2)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    // imty_vat(para) {
    //   if (para === 'VAT') {
    //     const no_vat = this.getfrom.perunit.replace(',', '');
    //     const gg = (Number(no_vat) * 7) / 107;
    //     this.getfrom.perunitvat = (Number(no_vat) + Number(gg)).toFixed(2);

    //     this.disabled = false;
    //   } else {
    //     this.getfrom.perunitvat = '';
    //     this.disabled = true;
    //   }
    // },
    async get_unit() {
      const { access_token } = await this.access_token();
      const url = `${API}unittype?_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.units = res.data.message.data;
    },
    async del_data(del_data) {
      console.log(del_data);

      const { access_token } = await this.access_token();
      const url = `${API}inventoryList?equipment_code=${del_data.equipment_code}&e_id=${del_data.e_id}&refkey=${del_data.refkey}&_page=1&_limit=100`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message.total);

      if (res.data.message.total !== 0) {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "ลบข้อมูลไม่ได้",
          text: `เลขทะเบียน ${del_data.equipment_code} มีทรัพย์สินลงทะเบียนเเล้ว`,
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      }

      Swal.fire({
        title: "คุณต้องการจะลบข้อมูลใช่หรือไม่",
        text: `เลขทะเบียน ${del_data.equipment_code}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },

        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          // console.log(del_data);
          const { access_token } = await this.access_token();
          const url = `${API}receiveAccess/${del_data.id}&${del_data.equipment_code}&${del_data.refkey}`;
          console.log(url);
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          //01-002-0001/65
          // console.log(url);

          const res = await axios.delete(url, head);
          // console.log(res);
          Swal.fire({
            icon: "success",
            title: "ลบข้อมูลสำเร็จ!",
            text: `รายการนี้ ถูกลบเเล้ว ${del_data.equipment_code}`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.getdatatable();
        }
      });
    },
    async edit_input(data_edit) {
      console.log(data_edit);
      console.log(data_edit.unittype);
      // console.log(this.getfrom.admitdate);
      // console.log(this.getfrom.voucherx_date);
      // console.log(data_edit.response_person);
      // console.log(this.getfrom.delivery_invoice_date);

      const { access_token } = await this.access_token();
      let data = new FormData();
      data.append("e_gid", data_edit.e_gid);
      data.append("e_id", data_edit.e_id);
      data.append("e_name", data_edit.e_name);
      data.append("budget_year", data_edit.budget_year);
      data.append("information", data_edit.information);
      data.append("amount", data_edit.amount);
      data.append("unitprice", this.getfrom.perunit.replace(",", ""));
      data.append("unitprice_vat", this.unit_price_vat.replace(",", ""));
      data.append("gf_code", data_edit.gf_code || " ");
      data.append("equipment_code", data_edit.equipment_code);
      data.append("admitdate", this.getfrom.admitdate);
      data.append("important_number", data_edit.important_number);
      data.append("vender", data_edit.vender);
      data.append("vender_address", data_edit.vender_address);
      data.append("vender_phone", data_edit.vender_phone);
      data.append("vender_fax", data_edit.vender_fax);
      data.append(
        "important_date",
        `${this.getfrom.voucherx_date === "undefined-undefined-" ? " " : this.getfrom.voucherx_date}`
      );
      data.append("invoice_number", data_edit.invoice_number);
      data.append(
        "invoice_date",
        `${this.getfrom.delivery_invoice_date === "undefined-undefined-" ? " " : this.getfrom.delivery_invoice_date}`
      );
      data.append("e_image", data_edit.e_image);
      data.append("e_lifetime", data_edit.e_lifetime);
      data.append("vender_email", data_edit.vender_email);
      data.append("vender_taxnumber", data_edit.vender_taxnumber);

      data.append("unittype", `${data_edit.unittype.name ? data_edit.unittype.name : data_edit.unittype}`);
      data.append("supply_by", `${data_edit.supply_by.supply ? data_edit.supply_by.supply : data_edit.supply_by}`);
      data.append(
        "budget_type",
        `${data_edit.budget_type.money_type ? data_edit.budget_type.money_type : data_edit.budget_type}`
      );
      data.append(
        "evidence_type",
        `${data_edit.evidence_type.evidence ? data_edit.evidence_type.evidence : data_edit.evidence_type}`
      );

      data.append("work_gid", this.workG.work_gid || data_edit.work_gid);
      data.append(
        "response_person",
        `${data_edit.response_person.firstname ? data_edit.response_person.firstname : this.getfrom.voucher}`
      );
      data.append("flag_calculate", this.depreciation);
      axios
        .put(API + "receiveAccess/" + data_edit.id, data, {
          headers: {
            authorization: await access_token,
          },
        })
        .then((response) => {
          console.log(response);
          // this.$bvModal.hide('modal-1');
          // this.getdatatable();
          Swal.fire({
            position: "center",
            icon: "success",
            title: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          location.reload();
          this.getdatatable();
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: error.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },

    arrayPost() {
      if (this.getfrom.quantity.toString() === "0") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `ใส่จำนวนที่ถูกต้อง`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.arrdata.push({
          e_gid: this.getfrom.typeG.e_gid,
          e_id: this.getfrom.typeG.e_id,
          e_name: this.getfrom.typeG.e_name,
          budget_year: this.getfrom.idg,
          information: this.getfrom.information,
          amount: this.getfrom.quantity,
          unittype: this.getfrom.unit.name,
          unitprice: this.getfrom.perunit.replace(",", ""),
          unitprice_vat:
            this.vat_switch === false ? this.getfrom.perunit.replace(",", "") : this.unit_price_vat.replace(",", ""),
          work_gid: this.workG.work_gid,
          response_person: this.getfrom.voucher.firstname,
          gf_code: this.getfrom.gf_code,
          equipment_code: this.registration_number,
          admitdate: this.getfrom.admitdate,
          supply_by: this.getfrom.provided.supply,
          budget_type: this.moneytype.money_type,
          important_number: this.getfrom.voucherx,
          vender: this.getfrom.vender,
          vender_address: this.getfrom.vender_address,
          vender_phone: this.getfrom.vender_phone,
          vender_fax: this.getfrom.vender_fax,
          invoice_number: this.getfrom.delivery_invoice,
          e_image: this.file1,
          e_lifetime: this.e_lifetime,
          vender_email: this.getfrom.vender_email,
          vender_taxnumber: this.getfrom.vender_taxnumber,
          important_date: `${this.getfrom.voucherx_date === "undefined-undefined-" ? " " : this.getfrom.voucherx_date}`,
          invoice_date: `${
            this.getfrom.delivery_invoice_date === "undefined-undefined-" ? " " : this.getfrom.delivery_invoice_date
          }`,
          evidence_type: this.getfrom.selected.evidence || " ",
          flag_calculate: this.depreciation,
        });
        // console.log(this.arrdata);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "เพิ่มรายการรับเข้า",
            variant: "success",
          },
        });
      }
    },

    dateField: async function (props) {
      console.log(props);
      this.$router.push({
        name: "in-inputkuruplun",
        params: {
          rowData: {
            id: props.row.id,
            e_name: props.row.e_name,
            e_id: props.row.e_id,
            e_gid: props.row.e_gid,
            budget_year: props.row.budget_year,
            information: props.row.information,
            unittype: props.row.unittype,
            unitprice: props.row.unitprice,
            unitprice_vat: props.row.unitprice_vat,
            admitdate: props.row.admitdate,
            equipment_code: props.row.equipment_code,
            amount: props.row.amount,
            work_gid: props.row.work_gid,
            evidence_type: props.row.evidence_type,
            response_person: props.row.response_person,
            important_number: props.row.important_number,
            work_gid: props.row.work_gid,
            date_raw: props.row.date_raw,
            refkey: props.row.refkey,
          }, // or anything you want
        },
      });
    },

    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รับเข้าทรัพย์สิน",
        columns: [
          {
            title: "รับเข้าทรัพย์สิน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วันที่-เดือน-ปี",
                field: "admitdate",
              },
              {
                title: "เลขทะเบียน",
                field: "equipment_code",
              },
              {
                title: "รหัสทรัพย์สิน",
                field: "e_gid_e_id",
              },
              {
                title: "ชื่อทรัพย์สิน",
                field: "e_name",
                thClass: "text-center",
              },

              {
                title: "หน่วยนับ",
                field: "unittype",
              },
              {
                title: "ราคาต่อหน่วย",
                field: "unitprice_ex",
                type: "number",
                width: "130px",
              },
              {
                title: "ราคาต่อหน่วย + vat7%",
                field: "unitprice_vat_ex",
                type: "number",
                width: "190px",
              },
            ],
          },
        ],
      });
    },
    async changeRoute(event) {
      const e_gid = event.e_gid;
      const { access_token } = await this.access_token();
      const url = `${API}equipmentNames?_page=1&_limit=100&_sort=1&e_gid=${e_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);

      this.typeGs = res.data.message.data.map((element) => {
        return {
          ...element,
          e_id_e_name: `${element.e_id} - ${element.e_name}`,
        };
      });
    },

    async equipmentg() {
      const { access_token } = await this.access_token();
      const url = `${API}equipmentGroups?_limit=100&_sort=1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);
      const res_amp = res.data.message.data.map((element) => {
        return {
          ...element,
          e_gname: `${element.e_gid} - ${element.e_gname}`,
        };
      });
      this.selectedGs = res_amp;
      this.property_code = this.selectedG.e_gid;
    },
    async ecode() {
      const { access_token } = await this.access_token();
      const url = `${API}equipmentCodes?equipment_code=ตร`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message.equipment_code_mix);

      this.getfrom.registration = await `${res.data.message.equipment_code_mix}`;
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    perPageChanged(value) {
      console.log(value.currentPerPage);
      this.pageLength = value.currentPerPage;
      this.getdatatable();
    },
    pageChanged(value) {
      console.log(value.currentPage);
      this.currentPage = value.currentPage;
      this.getdatatable();
    },
    async getdatatable() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}receiveAccess?_sort=-1&_page=${this.currentPage}&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message);
      this.total = res.data.message.total;
      let idx = Math.ceil(Number(this.total) / 100);
      let res_pdf = [];
      for (let index = 1; index <= idx; index++) {
        const url_pdf = `${API}receiveAccess?_sort=-1&_page=${index}&_limit=100`;
        const res_pdfs = await axios.get(url_pdf, head);
        const { data } = res_pdfs.data.message;
        res_pdf.push(...data);
      }
      this.rows = res_pdf.map((element) => {
        return {
          ...element,
          e_gid_e_id: `${element.e_gid}-${element.e_id}`,
          date_raw: element.admitdate,
          admitdate: `${parseInt(element.admitdate.split("-")[2])} ${
            this.month[parseInt(element.admitdate.split("-")[1])]
          } ${Number(element.admitdate.split("-")[0]) + 543}`,
          unitprice_ex: Number(element.unitprice),
          unitprice_vat_ex: Number(element.unitprice_vat),
        };
      });
      this.show = false;
      return;
      this.rows = res.data.message.data.map((element) => {
        return {
          ...element,
          e_gid_e_id: `${element.e_gid}-${element.e_id}`,
          date_raw: element.admitdate,
          admitdate: `${parseInt(element.admitdate.split("-")[2])} ${
            this.month[parseInt(element.admitdate.split("-")[1])]
          } ${Number(element.admitdate.split("-")[0]) + 543}`,
          unitprice_ex: Number(element.unitprice),
          unitprice_vat_ex: Number(element.unitprice_vat),
        };
      });
      this.show = false;
    },

    async postdata() {
      // await this.ecode();
      console.log(this.e_lifetime);
      if (this.e_lifetime === "0") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "อายุการใช้งาน ไม่ควรใส่ 0 ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      const { access_token_eg } = await this.access_token();
      const head_eg = {
        headers: {
          Authorization: await access_token_eg,
        },
      };
      const eg = `${API}cancelReservation?equipment_code=${this.registration_number}&e_id=${this.getfrom.typeG.e_id}`;
      const res_eg = await axios.get(eg, head_eg);
      console.log(res_eg.data.status);
      console.log("asdasd");

      if (this.arrdata.length > 0) {
        const mit = this.arrdata.length - 1;
        // console.log('เพิ่ม');
        this.arrdata.forEach(async (element, index) => {
          let data = new FormData();
          data.append("e_gid", element.e_gid);
          data.append("e_id", element.e_id);
          data.append("e_name", element.e_name);
          data.append("budget_year", element.budget_year);
          data.append("information", element.information);
          data.append("amount", element.amount);
          data.append("unittype", element.unittype);
          data.append("unitprice", element.unitprice);
          data.append("unitprice_vat", element.unitprice_vat);
          data.append("work_gid", element.work_gid);
          data.append("response_person", element.response_person);
          data.append("gf_code", element.gf_code || " ");
          data.append("equipment_code", element.equipment_code);
          data.append("admitdate", element.admitdate);
          data.append("supply_by", element.supply_by);
          data.append("budget_type", element.budget_type);
          data.append("important_number", element.important_number);
          data.append("evidence_type", element.evidence_type);
          data.append("vender", element.vender);
          data.append("vender_address", element.vender_address);
          data.append("vender_phone", element.vender_phone);
          data.append("vender_fax", element.vender_fax);
          data.append("important_date", element.important_date);
          data.append("invoice_number", element.invoice_number);
          data.append("invoice_date", element.invoice_date);
          data.append("e_image", element.e_image);
          data.append("e_lifetime", element.e_lifetime);
          data.append("vender_email", element.vender_email);
          data.append("vender_taxnumber", element.vender_taxnumber);
          data.append("equipment_code_gen", this.equipment_code_gen);
          data.append("flag_calculate", element.flag_calculate);

          const { access_token } = await this.access_token();
          axios
            .post(API + "receiveAccess", data, {
              headers: {
                authorization: access_token,
              },
            })
            .then((response) => {
              this.$bvModal.hide("modal-1");
              this.getdatatable();
            })
            .catch((error) => {
              Swal.fire({
                position: "top-center",
                icon: "error",
                title: error.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            });
          if (mit === index) {
            location.reload();
          }
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: `เพิ่มรายการรับเข้า ${this.arrdata.length} รายการ`,
            variant: "success",
          },
        });
      } else {
        if (this.getfrom.quantity.toString() === "0") {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `ใส่จำนวนที่ถูกต้อง`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          console.log("---------------------------");
          //  console.log(this.getfrom.selected.evidence)
          let data = new FormData();
          data.append("e_gid", this.getfrom.typeG.e_gid);
          data.append("e_id", this.getfrom.typeG.e_id);
          data.append("e_name", this.getfrom.typeG.e_name);
          data.append("budget_year", this.getfrom.idg);
          data.append("information", this.getfrom.information);
          data.append("amount", this.getfrom.quantity);
          data.append("unittype", this.getfrom.unit.name);
          data.append("unitprice", this.getfrom.perunit.replace(",", ""));
          data.append(
            "unitprice_vat",
            this.vat_switch === false ? this.getfrom.perunit.replace(",", "") : this.unit_price_vat.replace(",", "")
          );
          data.append("work_gid", this.workG.work_gid);
          data.append("response_person", this.getfrom.voucher.firstname);
          data.append("gf_code", this.getfrom.gf_code || " ");
          data.append("equipment_code", this.registration_number);
          data.append("admitdate", this.getfrom.admitdate);
          data.append("supply_by", this.getfrom.provided.supply);
          data.append("budget_type", this.moneytype.money_type);
          data.append("important_number", this.getfrom.voucherx || " ");
          data.append("evidence_type", this.getfrom.selected.evidence || " ");
          data.append("vender", this.getfrom.vender);
          data.append("vender_address", this.getfrom.vender_address);
          data.append("vender_phone", this.getfrom.vender_phone);
          data.append("vender_fax", this.getfrom.vender_fax);

          data.append(
            "important_date",
            `${this.getfrom.voucherx_date === "undefined-undefined-" ? " " : this.getfrom.voucherx_date}`
          );
          data.append("invoice_number", this.getfrom.delivery_invoice || " ");

          data.append(
            "invoice_date",
            `${
              this.getfrom.delivery_invoice_date === "undefined-undefined-" ? " " : this.getfrom.delivery_invoice_date
            }`
          );
          // data.append('e_image', this.file1);
          if (this.file1 != null) {
            this.file1.forEach((element) => {
              data.append("e_image", element);
            });
          }
          data.append("e_lifetime", this.e_lifetime);
          data.append("vender_email", this.getfrom.vender_email);
          data.append("vender_taxnumber", this.getfrom.vender_taxnumber);
          data.append("equipment_code_gen", this.equipment_code_gen);
          data.append("flag_calculate", this.depreciation);
          for (const value of data) {
            console.log(value);
          }

          const { access_token } = await this.access_token();
          const url = `${API}receiveAccess`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          const res = await axios.post(url, data, head);
          console.log(res);
          if (res.data.status == 200) {
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "บันทึก",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$bvModal.hide("modal-1");
            this.getdatatable();
            location.reload();
          } else {
            Swal.fire({
              position: "top-center",
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
          // console.log(res);

          this.getdatatable();
        }
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
